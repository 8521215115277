import './Register.scss';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import ballLogo from '../../assets/images/logo.png';

const connection = process.env.REACT_APP_API_URL;

const Register = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (!firstname.trim()) {
      newErrors.firstname = 'First name is required';
    }

    if (!lastname.trim()) {
      newErrors.lastname = 'Last name is required';
    }

    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!password.trim()) {
      newErrors.password = 'Password is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const registerObj = {
          firstname,
          lastname,
          email,
          password
        };

        await axios.post(`${connection}/register`, registerObj);
        navigate('/login');
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <section className="register">
      <div className="register__container">
        <section className="form">
          <form action="" className='form__register' onSubmit={handleRegister}>
            <img src={ballLogo} alt="Black & White Golf Ball" className='form__image'/>

            <h2 className="form__title">Join Your Tour</h2>

            <div className="form__input">
              <div className="form__input--errors">
                <label htmlFor="first__name__input" className='form__label'>First Name:</label>
                {errors.firstname && <p className="error-message">{errors.firstname}</p>}
              </div>
              <input type="text" name='first__name__input' className='first__name__input' placeholder='first name' onChange={(event) => setFirstName(event.target.value)} autoComplete='on'/>
            </div>

            <div className="form__input">
              <div className="form__input--errors">
                <label htmlFor="last__name__input" className='form__label'>Last Name:</label>
                {errors.lastname && <p className="error-message">{errors.lastname}</p>}
              </div>
              <input type="text" name='last__name__input' className='last__name__input' placeholder='last name' onChange={(event) => setLastName(event.target.value)} autoComplete='on'/>
            </div>

            <div className="form__input">
              <div className="form__input--errors">
                <label htmlFor="email__input" className='form__label'>Email:</label>
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
              <input type="text" name='email__input' className='email__input' placeholder='email@email.com' onChange={(event) => setEmail(event.target.value)} autoComplete='on'/>
            </div>

            <div className="form__input">
              <div className="form__input--errors">
                <label htmlFor="password__input" className='form__label'>Password:</label>
                {errors.password && <p className="error-message">{errors.password}</p>}
                {errors.firstname && <p className="error-message">{errors.firstname}</p>}
              </div>
              <input type="password" name='password__input' className='password__input' placeholder='password' onChange={(event) => setPassword(event.target.value)} autoComplete='on'/>
            </div>

            <button type='submit' className='register__button'>Register</button>

            <span><p className="login__text">Already have an account? <Link to='/login' className='login__link'>Sign In</Link></p></span>
          </form>
        </section>
      </div>
    </section>
  );
};

export default Register;
