import React, { useState, useEffect } from 'react';
import './DropDownHeader.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import menuIcon from '../../assets/icons/menu-icon.svg';
import arrowDown from '../../assets/icons/arrow_drop_down-24px.svg';

const connection = process.env.REACT_APP_API_URL;

const DropDownHeader = () => {
  const [showList, setShowList] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const navigate = useNavigate();
  

  // Navigation logic based on the current route
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const isRegistrationPage = location.pathname === '/register';
  const isAboutPage = location.pathname === '/about';


  // Function to get the users data from the db
  const fetchUserData = async () => {

    // Check if the current page is 'about'
    if (location.pathname === '/about') {
      setLoading(false);
      return; // Do not proceed with fetching user data or checking tokens
    }

    try {
      const token = localStorage.getItem("jwtToken");

      if (!token) {
        navigate('/login'); // Redirect to the login page if token is not present
        return;
      }

      const response = await axios.get(`${connection}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUser(response.data);
    } catch (error) {
      console.error(error);

      if (error.response && error.response.status === 401) {
        localStorage.removeItem("jwtToken");
        navigate('/login'); // Redirect to the login page if token is expired
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect hook to fetch user data when the component mounts
  useEffect(() => {
    fetchUserData();
  }, []); 

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    setUser(null);
    navigate('/login');
  };

   // If on login or registration page, render nothing
   if (isLoginPage || isRegistrationPage || isAboutPage) {
    return null; 
  }

  if (['/login', '/register', '/about'].includes(location.pathname)) {
    return null;  // Do not render header on these pages
  }

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
      <div className='drop-header'>
          <nav className="drop-header__nav-desktop">
          <h2 className="drop-header__title">The Tour</h2>
              
              <div className="drop-header__links-container">
                  <Link to='/' className='drop-header__link'>Dashboard</Link>
                  <Link to="activity" className='drop-header__link'>Activity Feed</Link>
                  <Link to='/start' className='drop-header__link'>Rounds</Link>
                  <Link to="tourslist" className='drop-header__link'>Tours</Link>
                  <Link to="findcourses" className='drop-header__link'>Courses</Link>
                  <Link to="friendslist" className='drop-header__link'>Friends</Link>
              </div>

              {!user && !isLoginPage && !isRegistrationPage ? window.location.reload() : true}
              {user && (
                <div className="drop-header__container-desktop" onMouseOver={() => setShowMore(true)}>
                  <button className='drop-header__show-more'>
                  
                    {user.first_name && (<h3 className="show-more__letter">{user.first_name}</h3>)}
                    <img src={arrowDown} alt="drop down arrow" />
                  </button>

                  <ul className={showMore ? "show" : "hide"} onMouseOut={() => setShowMore(false)}>
                    <li className="drop-header__item"><Link to='/editprofile' className='drop-header__link' onClick={() => setShowMore(false)}>Edit Profile</Link></li>
                    <li className="drop-header__item" onClick={() => handleLogout()}><Link to='/login' className='drop-header__link' onClick={() => setShowMore(false)}>Sign Out</Link></li>
                  </ul>
                </div>
              )}
              
          </nav>

          <nav className="drop-header__nav-mobile">
          <h2 className="drop-header__title">The Tour</h2>

              <div className="drop-header__container"  onMouseLeave={() => {setShowList(false)}}>
                  <button className="drop-header__button" onClick={() => setShowList(!showList)}>
                      <img src={menuIcon} alt="menu icon" />
                  </button>

                  <ul className={showList ? "show" : "hide"}>
                      <li className="drop-header__item"><Link to='/' className='drop-header__link'>Dashboard</Link></li>
                      <li className="drop-header__item"><Link to='/activity' className='drop-header__link'>Activity Feed</Link></li>
                      <li className="drop-header__item"><Link to='/start' className='drop-header__link'>Start Round</Link></li>
                      <li className="drop-header__item"><Link to="tourslist" className='drop-header__link'>Tours</Link></li>
                      <li className="drop-header__item"><Link to='/findcourses' className='drop-header__link'>Courses</Link></li>
                      <li className="drop-header__item"><Link to='/friendslist' className='drop-header__link'>Friends</Link></li>
                      <li className="drop-header__item"><Link to='/editprofile' className='drop-header__link' onClick={() => setShowMore(false)}>Edit Profile</Link></li>
                      <li className="drop-header__item"><Link to='/login' className='drop-header__link' onClick={() => handleLogout()}>Sign Out</Link></li>
                  </ul>
              </div>
          </nav>
      </div>
  );
};

export default DropDownHeader;