import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner/Spinner'; // Make sure you have a spinner component
import axios from 'axios';
import './FindCourses.scss';

const connection = process.env.REACT_APP_API_URL;

const FindCourses = () => {
  const [courseData, setCourseData] = useState([]);
  const [filteredCourseData, setFilteredCourseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failedAuth, setFailedAuth] = useState(false);
  const [cityFilter, setCityFilter] = useState('');
  const [numHolesFilter, setNumHolesFilter] = useState('All');
  const [priceFilter, setPriceFilter] = useState('');

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem("jwtToken");

        if (!token) {
          return setFailedAuth(true);
        }

        const response = await axios.get(`${connection}/courses/pricing`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setCourseData(response.data);
        setFilteredCourseData(response.data); // Initialize with full course data
        setLoading(false);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setFailedAuth(true);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const filteredCourses = courseData.filter(course => {
      return (
        (!cityFilter || course.city === cityFilter) &&
        (numHolesFilter === "All" || parseInt(course.num_holes) === parseInt(numHolesFilter)) &&
        (!priceFilter || course.pricing.some(price => price.price <= parseFloat(priceFilter)))
      );
    });
  
    setFilteredCourseData(filteredCourses);
  }, [cityFilter, numHolesFilter, priceFilter, courseData]);

  const resetFilters = () => {
    setCityFilter('');
    setNumHolesFilter('All');
    setPriceFilter('');
    setFilteredCourseData(courseData); // Reset the filtered data
  };

  if (failedAuth) {
    return (
      <section className='loading'>
        <h3 className="loading__title">Authentication failed. Please log in.</h3>
        <button><Link to='/login'>Please Log In</Link></button>
      </section>
    );
  }

  if (loading) {
    return <Spinner />; // Render Spinner component during loading
  }

  return (
    <div className="courses">
      <div className="courses__filter">
        <h3 className="filter__title">Filters</h3>
        <div className="filter__section">
          <label htmlFor="city" className="filter__label">City</label>
          <select
            name="city"
            id="city"
            className="filter__select"
            value={cityFilter}
            onChange={(e) => setCityFilter(e.target.value)}
          >
            <option value="" className="filter__option" hidden>Select City</option>
            {[...new Set(courseData.map(course => course.city))].map((city, index) => (
              <option key={index} value={city} className="filter__option">{city}</option>
            ))}
          </select>
        </div>

        <div className="filter__section">
          <label htmlFor="numHoles" className="filter__label">Holes</label>
          <select
            name="numHoles"
            id="numHoles"
            className="filter__select"
            value={numHolesFilter}
            onChange={(e) => setNumHolesFilter(e.target.value)}
          >
            <option value="All" className="filter__option" hidden>All</option>
            {[...new Set(courseData.map(course => course.num_holes))].map((numHoles, index) => (
              <option key={index} value={numHoles} className="filter__option">{numHoles}</option>
            ))}
          </select>
        </div>

        <div className="filter__section">
          <label htmlFor="price" className="filter__label">Price</label>
          <select
            name="price"
            id="price"
            className="filter__select"
            value={priceFilter}
            onChange={(e) => setPriceFilter(e.target.value)}
          >
            <option value="" className="filter__option" hidden>Select Price</option>
            {[...new Set(courseData.flatMap(course => course.pricing.map(price => price.price)))]
              .sort((a, b) => a - b)
              .map((price, index) => (
                <option key={index} value={price} className="filter__option">{`$${price.toFixed(2)}`}</option>
            ))}
          </select>
        </div>

        <button className="filter__button-reset" onClick={resetFilters}>
          Reset
        </button>
      </div>

      <section className="courses__tiles">
        {filteredCourseData.length > 0 ? (
          filteredCourseData.map(course => (
            <div className="courses__tile" key={course.course_id}>
              {course.course_image_path && (
    <img
        src={`${connection}/assets/images/courseImages/${course.course_image_path}`}
        alt={course.course_name}
        className="courses__image"
        loading="lazy" // Lazy load images
    />
)}

              <div className="courses__details">
                <h4 className="courses__title">{course.course_name}</h4>
                <p className="courses__location">{course.city}, {course.province}</p>
              </div>
              <div className="courses__actions">
                <p className="courses__holes">Holes: {course.num_holes}</p>
                <div className="courses__prices">
                  {course.minPrice !== null && course.maxPrice !== null ? (
                    <p>
                      Price: ${course.minPrice.toFixed(2)} - ${course.maxPrice.toFixed(2)}
                    </p>
                  ) : (
                    <p>Price: N/A</p>
                  )}
                </div>
                <button className="courses__book">
                  <a href={course.course_website_link} target='_blank' rel='noopener noreferrer'>
                    Book Time
                  </a>
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No courses found matching your criteria.</p>
        )}
      </section>
    </div>
  );
};

export default FindCourses;
