import GroupScorecard from '../../components/GroupScorecard/GroupScorecard';
import LeaderBoard from '../../components/LeaderBoard/LeaderBoard';
import LeaderBoardLeader from '../../components/LeaderBoardLeader/LeaderBoardLeader';
import LeaderBoardTourName from '../../components/LeaderBoardTourName/LeaderBoardTourName';
import './LeaderBoardPage.scss';

const LeaderBoardPage = () => {
    return (
        <div className='leaderboard__page'>
            <LeaderBoardTourName />
            {/* <LeaderBoardLeader /> */}
            <LeaderBoard />
            <GroupScorecard />
        </div>
    );
};

export default LeaderBoardPage;