import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserStats.scss';
import { FaGolfBall, FaFlag, FaChartLine } from 'react-icons/fa';
import { GiGolfFlag, GiGolfClub } from 'react-icons/gi';
import { GiGolfTee } from "react-icons/gi";
import { RiGolfBallFill } from "react-icons/ri";

const connection = process.env.REACT_APP_API_URL;

const UserStats = () => {
    const [user, setUser] = useState(null);
    const [stats, setStats] = useState({
        averageScore: null,
        handicap: null,
        averagePars: null,
        fairwaysHitPercentage: null,
        greensInRegulationPercentage: null,
        twoPuttsPercentage: null,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [failedAuth, setFailedAuth] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("jwtToken");

                if (!token) {
                    setFailedAuth(true);
                    setLoading(false);
                    return;
                }

                const responseUser = await axios.get(`${connection}/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const userData = responseUser.data;
                setUser(userData);

                if (userData && userData.user_id) {
                    const responseStats = await axios.get(`${connection}/user/${userData.user_id}/stats`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    const statsData = responseStats.data;

                    setStats({
                        averageScore: statsData.averageScore,
                        averagePars: statsData.averagePars,
                        handicap: statsData.handicap,
                        fairwaysHitPercentage: statsData.fairwaysHitPercentage,
                        greensInRegulationPercentage: statsData.greensInRegulationPercentage,
                        twoPuttsPercentage: statsData.twoPuttsPercentage,
                    });
                } else {
                    throw new Error("User ID is undefined");
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div className="user-stats loading">Loading...</div>;
    }

    if (error) {
        return <div className="user-stats error">Error: {error}</div>;
    }

    if (failedAuth) {
        return <div className="user-stats error">Authentication failed. Please log in again.</div>;
    }

    return (
        <div className="user__statistics">
            <h2 className="user__statistics--title">Your Stats</h2>
            <div className="user__statistics--container">
                <div className="stat__card">
                    <FaChartLine className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">Score/18</span>
                        <span className="stat__value">{stats.averageScore}</span>
                    </div>
                </div>
                <div className="stat__card">
                    <FaFlag className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">Pars/18</span>
                        <span className="stat__value">{stats.averagePars}</span>
                    </div>
                </div>
                <div className="stat__card">
                    <GiGolfTee className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">Fairways Hit</span>
                        <span className="stat__value">{stats.fairwaysHitPercentage}%</span>
                    </div>
                </div>
                <div className="stat__card">
                    <RiGolfBallFill className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">G.I.R.</span>
                        <span className="stat__value">{stats.greensInRegulationPercentage}%</span>
                    </div>
                </div>
                <div className="stat__card">
                    <GiGolfFlag className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">2-Putts</span>
                        <span className="stat__value">{stats.twoPuttsPercentage}%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserStats;
