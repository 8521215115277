import './GroupCourseSelect.scss';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const connection = process.env.REACT_APP_API_URL;

const GroupCourseSelect = () => {
  const [courses, setCourses] = useState([]);
  const [organizedCourses, setOrganizedCourses] = useState({});
  const [groups, setGroups] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [failedAuth, setFailedAuth] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        if (!token) {
          setFailedAuth(true);
          return;
        }
        const response = await axios.get(`${connection}/courses`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const sortedCourses = response.data.sort((a, b) =>
          a.city.localeCompare(b.city, undefined, { sensitivity: 'base' })
        );

        const cityGroups = sortedCourses.reduce((acc, course) => {
          const { city } = course;
          if (!acc[city]) acc[city] = [];
          acc[city].push(course);
          return acc;
        }, {});

        setCourses(sortedCourses); // Now `courses` is populated with the fetched data
        setOrganizedCourses(cityGroups);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setFailedAuth(true);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const token = localStorage.getItem("jwtToken");

        if (!token) {
          return setFailedAuth(true);
        }

        const response = await axios.get(`${connection}/tours`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setGroups(response.data.userTours);
      } catch (err) {
        console.log(err);
      }
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      const courseId = Number(selectedCourse);
      const courseDetails = courses.find(course => course.course_id === courseId);
      if (courseDetails && courseDetails.tee_off_block) {
        setBlocks(courseDetails.tee_off_block.split(',').map(block => block.trim()));
      } else {
        setBlocks([]);
      }
    } else {
      setBlocks([]);
    }
  }, [selectedCourse, courses]);

  if (failedAuth) {
    return (
      <section className='loading'>
        <h3 className="loading__title">Authentication failed. Please log in.</h3>
        <button><Link to='/login'>Please Log In</Link></button>
      </section>
    );
  }

  return (
    <section className="select-course__container">
      <section className="select-course">
        <h2 className="select-course__title">Where are you playing?</h2>

        <div className="select-course__section">
          <label htmlFor="select-course__list">Select Your Course:</label>
          <select
            id="select-course__list"
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
          >
            <option value="" hidden>Choose Your Course</option>
            {Object.entries(organizedCourses).map(([city, cityCourses]) => (
              <optgroup label={city} key={city}>
                {cityCourses.map(({ course_id, course_name }) => (
                  <option key={course_id} value={course_id.toString()}>
                    {course_name}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>

        <div className="select-course__section">
          <label htmlFor="select-course__blocks">Select Your Block:</label>
          <select
            id="select-course__blocks"
            value={selectedBlock}
            onChange={(e) => setSelectedBlock(e.target.value)}
          >
            <option value="" hidden>Choose Your Block</option>
            {blocks.map((block, index) => (
              <option key={index} value={block}>
                {block}
              </option>
            ))}
          </select>
        </div>

        <div className="select-course__section">
          <label htmlFor="select-course__group">Select Your Group:</label>
          <select
            name="select-course__group"
            id="select-course__group"
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
          >
            <option value="" hidden>
              Choose Your Group
            </option>
            {groups.map((group) => (
              <option key={group.party_id} value={group.party_id}>
                {group.party_name}
              </option>
            ))}
          </select>
        </div>

        <Link to={`/grouptrack/${selectedCourse}/${selectedBlock}/${selectedGroup}`}>
          <button className="select-course__start">Tee Off</button>
        </Link>
      </section>
    </section>
  );
};

export default GroupCourseSelect;
