import React, { useState, useEffect } from 'react';
import './ScoreInput.scss';
import nextHole from '../../assets/icons/arrow-forward.png';
import prevHole from '../../assets/icons/arrow-back.png';
import { useParams, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';

const connection = process.env.REACT_APP_API_URL;
const { v4: uuidv4 } = require('uuid');

const ScoreInput = () => {
  const [totalHoles, setTotalHoles] = useState(0);
  const [holes, setHoles] = useState([]);
  const [currentHole, setCurrentHole] = useState(0);
  const [courseData, setCourseData] = useState(null);
  const [holeData, setHoleData] = useState(null);
  const [failedAuth, setFailedAuth] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [scoreErrors, setScoreErrors] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [overUnderPar, setOverUnderPar] = useState(0);
  const [fairwaysHit, setFairwaysHit] = useState([]);
  const [greensInRegulation, setGreensInRegulation] = useState([]);
  const [twoPutts, setTwoPutts] = useState([]);
  const [putts, setPutts] = useState([]);
  const [advancedStats, setAdvancedStats] = useState({
    fairwaysHitPercentage: 0,
    greensInRegulationPercentage: 0,
    twoPuttsPercentage: 0
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const timestampToDdMmYyyy = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  }

  const handleScoreSave = (event) => {
    event.preventDefault();
    setShowConfirmation(true);
  };

  const saveScore = async () => {
    try {
      const gameDate = timestampToDdMmYyyy(new Date());
      const block = params.block;
      const roundId = uuidv4();
      const token = localStorage.getItem('jwtToken');

      const holeScoreObjects = [];

      for (let i = 0; i < totalHoles; i++) {
        const scoreId = uuidv4();
        const holeScoreObj = {
          score_id: scoreId,
          round_id: roundId,
          user_id: userId,
          course_id: courseData.course_id,
          game_date: gameDate,
          block: block,
          hole_number: i + 1,
          hole_score: holes[i],
          fairway_hit: fairwaysHit[i],
          green_in_regulation: greensInRegulation[i],
          two_putts: twoPutts[i],
          putts: putts[i]
        };

        holeScoreObjects.push(holeScoreObj);
      }

      await axios.post(`${connection}/savescore`, holeScoreObjects, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.setItem('newScoreSubmitted', 'true');
      localStorage.removeItem('savedScores');
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      const token = localStorage.getItem('jwtToken');
      axios.get(`${connection}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setUserId(response.data.user_id);
      }).catch((error) => {
        console.error(error);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        if (!token) {
          console.error("Authentication token not found");
          return;
        }

        const courseResponse = await axios.get(
          `${connection}/track/${params.id}/${params.block}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (courseResponse.data) {
          setCourseData(courseResponse.data.courseData);
          setCourseId(params.id);
          setHoleData(courseResponse.data.holesData);
          setTotalHoles(courseResponse.data.courseData.num_holes);

          setScoreErrors(Array(courseResponse.data.courseData.num_holes).fill(""));
          setFairwaysHit(Array(courseResponse.data.courseData.num_holes).fill(false));
          setGreensInRegulation(Array(courseResponse.data.courseData.num_holes).fill(false));
          setTwoPutts(Array(courseResponse.data.courseData.num_holes).fill(false));
          setPutts(Array(courseResponse.data.courseData.num_holes).fill(0));
        } else {
          console.error('Empty course data received.');
        }
      } catch (error) {
        console.error('Error: ', error);
      }
    };

    getCourse();
  }, [params.id, params.block]);

  useEffect(() => {
    const savedScores = JSON.parse(localStorage.getItem('savedScores'));
    if (savedScores && savedScores.courseId === params.id) {
      setHoles(savedScores.holes);
      setCurrentHole(savedScores.currentHole);
      setFairwaysHit(savedScores.fairwaysHit);
      setGreensInRegulation(savedScores.greensInRegulation);
      setPutts(savedScores.putts);
      setTwoPutts(savedScores.twoPutts);
    } else {
      setHoles(Array(totalHoles).fill(0));
    }
  }, [totalHoles, params.id]);

  useEffect(() => {
    if (courseId) {
      localStorage.setItem('savedScores', JSON.stringify({
        courseId,
        holes,
        currentHole,
        fairwaysHit,
        greensInRegulation,
        twoPutts,
        putts
      }));
    }
  }, [holes, currentHole, courseId, fairwaysHit, greensInRegulation, twoPutts, putts]);

  useEffect(() => {
    if (holes && fairwaysHit && greensInRegulation && twoPutts) {
      const scoreSum = holes.slice(0, currentHole + 1).reduce((acc, curr) => acc + curr, 0);
      setTotalScore(scoreSum);

      if (holeData) {
        const totalPar = holeData.slice(0, currentHole + 1).reduce((acc, curr) => acc + curr.par, 0);
        setOverUnderPar(scoreSum - totalPar);
      }

      const fairwaysHitCount = fairwaysHit.filter(hit => hit).length;
      const greensInRegulationCount = greensInRegulation.filter(gir => gir).length;
      const twoPuttsCount = twoPutts.filter(tp => tp).length;
      const validHolesCount = holes.filter(score => score > 0).length;

      setAdvancedStats({
        fairwaysHitPercentage: validHolesCount ? ((fairwaysHitCount / validHolesCount) * 100).toFixed(2) : 0,
        greensInRegulationPercentage: validHolesCount ? ((greensInRegulationCount / validHolesCount) * 100).toFixed(2) : 0,
        twoPuttsPercentage: validHolesCount ? ((twoPuttsCount / validHolesCount) * 100).toFixed(2) : 0
      });
    }
  }, [holes, holeData, currentHole, fairwaysHit, greensInRegulation, twoPutts, putts, totalHoles]);

  if (!courseData) {
    return (
      <section>
        <h3>One sec find some more tees</h3>
      </section>
    );
  }

  const incrementScore = () => {
    if (holes[currentHole] < 20) {
      setHoles((prevHoles) => {
        const updatedHoles = [...prevHoles];
        updatedHoles[currentHole]++;
        return updatedHoles;
      });
    }
  };

  const decrementScore = () => {
    if (holes[currentHole] > 0) {
      setHoles((prevHoles) => {
        const updatedHoles = [...prevHoles];
        updatedHoles[currentHole]--;
        return updatedHoles;
      });
    }
  };

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      const updatedHoles = [...holes];
      updatedHoles[currentHole] = value;
      setHoles(updatedHoles);
    }
  };

  const handleCheckboxChange = (statType, index) => {
    const updateStat = (prevState) => {
      const updatedStat = [...prevState];
      updatedStat[index] = !updatedStat[index];
      return updatedStat;
    };

    switch (statType) {
      case 'fairwaysHit':
        setFairwaysHit(updateStat(fairwaysHit));
        break;
      case 'greensInRegulation':
        setGreensInRegulation(updateStat(greensInRegulation));
        break;
      case 'twoPutts':
        setTwoPutts(updateStat(twoPutts));
        break;
      default:
        break;
    }
  };

  if (failedAuth) {
    return (
      <section className='loading'>
        <h3 className="loading__title">Authentication failed. Please log in.</h3>
        <button><Link to='/login'>Please Log In</Link></button>
      </section>
    );
  }

  const handleNextHole = () => {
    if (currentHole < totalHoles - 1) {
      setCurrentHole(currentHole + 1);
    }
  };

  const handlePreviousHole = () => {
    if (currentHole > 0) {
      setCurrentHole(currentHole - 1);
    }
  };

  const closeModal = () => {
    setShowConfirmation(false);
  };

  const confirmSave = () => {
    setShowConfirmation(false);
    saveScore();
  };

  return (
    <div className='input__container'>
      <section className='header__image'>
      </section>

      <section className="score">

        <div className="score__info" key={courseData.course_id}>
          
          <p className="score__course">{courseData.course_name}</p>

          <div className='score__results'>
            <h4 className='score__total'>Total Score: {totalScore}</h4>
            <h4 className='score__result'>Over/Under: {overUnderPar >= 0 ? `+${overUnderPar}` : overUnderPar}</h4>
          </div>

          <div className="score__data">
            <h2 className="score__hole"> Hole {currentHole + 1}</h2>

            <div className="score__nums">
              <h4 className="score__par">{holeData[currentHole][params.block + '_yards']} yards</h4>
              <h4 className="score__distance">Par: {holeData[currentHole].par}</h4>
            </div>
          </div>

        </div>

        <div className="score__tracker">
          <button className="score__decrement" onClick={decrementScore}>-</button>

          {scoreErrors[currentHole] && (<p className="score__error">{scoreErrors[currentHole]}</p>)}
          <input
            type="number"
            className={`hole_${currentHole + 1}_score score__input`}
            value={holes[currentHole] !== undefined ? holes[currentHole] : 0}
            onChange={handleInputChange}
          />

          <button className="score__increment" onClick={incrementScore}>+</button>
        </div>

        <div className="score__stats">
          <label className="score__stats__stat-label">
            F.H.
            <input
              type="checkbox"
              checked={fairwaysHit[currentHole]}
              onChange={() => handleCheckboxChange('fairwaysHit', currentHole)}
            />
          </label>
          <label className="score__stats__stat-label">
            G.I.R.
            <input
              type="checkbox"
              checked={greensInRegulation[currentHole]}
              onChange={() => handleCheckboxChange('greensInRegulation', currentHole)}
            />
          </label>
          <label className="score__stats__stat-label">
            2-P
            <input
              type="checkbox"
              checked={twoPutts[currentHole]}
              onChange={() => handleCheckboxChange('twoPutts', currentHole)}
            />
          </label>
        </div>

        <div className="score__control">

          <div className="score__view">
            
            <button className="score__previous" onClick={handlePreviousHole}>
              <img src={prevHole} alt='previous hole icon' />
            </button>

            <button className="score__next" onClick={handleNextHole}>
              <img src={nextHole} alt="next hole icon" />
            </button>

          </div>

          <button className="score__save" onClick={handleScoreSave}>End Round</button>
        </div>
      </section>

      {showConfirmation && (
        <div className="modal__overlay">
          <div className="modal__front">
            <p className='modal__font'>Are you sure you want to end the round?</p>
            <button className='modal__button' onClick={confirmSave}>Yes</button>
            <button className='modal__button' onClick={closeModal}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScoreInput;
