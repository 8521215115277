import './GroupScorecard.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const connection = process.env.REACT_APP_API_URL;

const GroupScorecard = () => {
  const [failedAuth, setFailedAuth] = useState(false);
  const [groupScores, setGroupScores] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("jwtToken") || '');
  const { tourid } = useParams();

  useEffect(() => {
    const fetchGroupScores = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.get(`${connection}/tours/scores/${tourid}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        // console.log('Fetched Group Scores:', response.data);
        setGroupScores(response.data.rounds);
      } catch (error) {
        console.error("Error fetching group scores:", error);
        setFailedAuth(true);
      }
    };
  
    fetchGroupScores();
  }, [tourid]);

  function timestampToDdMmYyyyId(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day} / ${month} / ${year}`;
  }

  // console.log('Group Scores:', groupScores);

  const getStyledScore = (score, par) => {
    if (typeof score !== 'number' || typeof par !== 'number') {
      return <span className="score-error">Invalid</span>;
    }

    if (score === par) {
      return <span className="score-par">{score}</span>;
    } else if (score < par) {
      return <span className="score-less-than-par">{score}</span>;
    }
    return <span className="score-more-than-par">{score}</span>;
  };

  if (failedAuth) {
    return (
      <section className='loading'>
        <h3 className="loading__title">Authentication Failed</h3>
        <button><a href='/login'>Login Again</a></button>
      </section>
    );
  }

  if (!groupScores.length) {
    return (
      <section className='loading'>
        <h3 className="loading__title">No Scores Available</h3>
      </section>
    );
  }

  return (
    <div className='container'>
      {groupScores.map((data, index) => (
        <section className='scorecard' key={index}>
          <div className="scorecard__titles">
            <h3 className="scorecard__course">{data ? data.course_name : 'Unknown Course'}</h3>
            <div className="scorecard__left">
              <h3 className="scorecard__date">{timestampToDdMmYyyyId(data.round_date)}</h3>
            </div>
          </div>

          <table className='scorecard__table'>
            <tbody className='scorecard__body'>
              <tr className='scorecard__row group-row__1'>
                <th className='scorecard__row-title'>Hole</th>
                {/* Dynamically render hole numbers */}
                {data.holes.slice(0, 9).map((hole, index) => (
                  <td key={index} className='scorecard__hole'>{index + 1}</td>
                ))}
                <td className='scorecard__hole'>{data.holes.length > 9 ? 'IN' : 'Out'}</td>
              </tr>
              <tr className='scorecard__row group-row__2'>
                <th className='scorecard__row-title'>Par</th>
                {/* Dynamically render par values */}
                {data.holes.slice(0, 9).map((hole, index) => (
                  <td key={index} className="scorecard__par">{hole.hole_par}</td>
                ))}
                {/* Calculate and render In/Out par accordingly */}
                <td className="scorecard__par">{data.holes.slice(0, 9).reduce((acc, hole) => acc + hole.hole_par, 0)}</td>
              </tr>
              {Object.values(data.users).map((user, idx) => (
                <tr key={`user-${index}-${idx}`} className='scorecard__row group-row__3'>
                  <th className='scorecard__row-title'>{user.first_name} {user.last_name}</th>
                  {/* Dynamically render scores for the first 9 holes */}
                  {user.scores.slice(0, 9).map((score, idx) => (
                    <td key={idx} className="scorecard__score">{getStyledScore(score.user_score, data.holes[idx].hole_par)}</td>
                  ))}
                  {/* Calculate and render total score for the first 9 holes */}
                  <td className="scorecard__score">{user.scores.slice(0, 9).reduce((acc, score) => acc + score.user_score, 0)}</td>
                </tr>
              ))}
            {data.holes.length > 9 && data.holes.length <= 18 && (
                <>
                  {/* Render remaining holes for the second 9 if necessary */}
                  <tr className='scorecard__row group-row__4'>
                    <th className='scorecard__row-title'>Hole</th>
                    {data.holes.slice(9).map((hole, index) => (
                      <td key={index} className='scorecard__hole'>{index + 10}</td>
                    ))}
                    <td className='scorecard__hole'>OUT</td>
                  </tr>
                  <tr className='scorecard__row group-row__5'>
                    <th className='scorecard__row-title'>Par</th>
                    {data.holes.slice(9).map((hole, index) => (
                      <td key={index} className="scorecard__par">{hole.hole_par}</td>
                    ))}
                    {/* Calculate and render In/Out par accordingly for the second 9 holes */}
                    <td className="scorecard__par">{data.holes.slice(9).reduce((acc, hole) => acc + hole.hole_par, 0)}</td>
                  </tr>
                  {/* Render user scores for the second 9 holes */}
                  {Object.values(data.users).map((user, idx) => (
                    <tr key={`user-${index}-second-${idx}`} className='scorecard__row group-row__6'>
                      <th className='scorecard__row-title'>{user.first_name} {user.last_name}</th>
                      {/* Dynamically render scores for the second 9 holes */}
                      {user.scores.slice(9).map((score, idx) => (
                        <td key={idx} className="scorecard__score">{getStyledScore(score.user_score, data.holes[idx + 9].hole_par)}</td>
                      ))}
                      {/* Calculate and render total score for the second 9 holes */}
                      <td className="scorecard__score">{user.scores.reduce((acc, score) => acc + score.user_score, 0)}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </section>
      ))}
    </div>
  );
};

export default GroupScorecard;
