import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './CourseSelect.scss';

const connection = process.env.REACT_APP_API_URL;

const CourseSelect = () => {
  const [courses, setCourses] = useState([]);
  const [organizedCourses, setOrganizedCourses] = useState({});
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [blocks, setBlocks] = useState([]); // This will store the blocks for the selected course
  const [selectedBlock, setSelectedBlock] = useState(''); // This should be a string, representing the selected block
  const [failedAuth, setFailedAuth] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        if (!token) {
          setFailedAuth(true);
          return;
        }
        const response = await axios.get(`${connection}/courses`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        // Sort and organize courses by city
        const sortedCourses = response.data.sort((a, b) =>
          a.city.localeCompare(b.city, undefined, { sensitivity: 'base' })
        );
  
        // Organize courses by city
        const cityGroups = sortedCourses.reduce((acc, course) => {
          const { city } = course;
          if (!acc[city]) acc[city] = [];
          acc[city].push(course);
          return acc;
        }, {});
  
        // Set both the flat list of courses and the organized version
        setCourses(sortedCourses); // Now `courses` is populated with the fetched data
        setOrganizedCourses(cityGroups);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setFailedAuth(true);
      }
    };
  
    fetchCourses();
  }, []);


  useEffect(() => {
    if (selectedCourse) {
      // Convert selectedCourse to a number to ensure correct comparison
      const courseId = Number(selectedCourse);
      // Ensure 'courses' contains the expected flat list of courses
      const courseDetails = courses.find(course => course.course_id === courseId);
      if (courseDetails && courseDetails.tee_off_block) {
        // Correctly split and trim the tee_off_block string
        setBlocks(courseDetails.tee_off_block.split(',').map(block => block.trim()));
      } else {
        setBlocks([]);
      }
    } else {
      setBlocks([]);
    }
  }, [selectedCourse, courses]);


  if (failedAuth) {
    return (
      <section className='loading'>
        <h3 className="loading__title">Authentication failed. Please log in.</h3>
        <button><Link to='/login'>Please Log In</Link></button>
      </section>
    );
  }

  return (
    <section className="select-course__container">
      <section className="select-course">
        <h2 className="select-course__title">Where are you playing?</h2>

        <div className="select-course__section">
          <label htmlFor="select-course__list">Select Your Course:</label>
          <select
            id="select-course__list"
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
          >
            <option value="" hidden>Choose Your Course</option>
            {Object.entries(organizedCourses).map(([city, cityCourses]) => (
              <optgroup label={city} key={city}>
                {cityCourses.map(({ course_id, course_name }) => (
                  <option key={course_id} value={course_id.toString()}>
                    {course_name}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>

        <div className="select-course__section">
          <label htmlFor="select-course__blocks">Select Your Block:</label>
          <select
            id="select-course__blocks"
            value={selectedBlock}
            onChange={(e) => setSelectedBlock(e.target.value)}
          >
            <option value="" hidden>Choose Your Block</option>
            {blocks.map((block, index) => (
              <option key={index} value={block}>
                {block}
              </option>
            ))}
          </select>
        </div>

        <Link to={`/track/${selectedCourse}/${selectedBlock}`}>
          <button className="select-course__start">Tee Off</button>
        </Link>
      </section>
    </section>
  );
};

export default CourseSelect;
