import './LeaderBoardTourName.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const connection = process.env.REACT_APP_API_URL;

const LeaderBoardTourName = () => {
    const [group, setGroup] = useState([]);
    const [failedAuth, setFailedAuth] = useState(false);


    const { tourid } = useParams();
    const token = localStorage.getItem("jwtToken");

    // Function to get the tour data and set the group data
    useEffect(() => {
        const fetchGroup = async () => {
            if (!token || !tourid) {
                return setFailedAuth(true);
            }

            try {
                const groupResponse = await axios.get(`${connection}/tours/${tourid}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setGroup(groupResponse.data);
            } catch (err) {
                console.log(err);
                setFailedAuth(true);
            }
        };

        fetchGroup();
    }, [tourid, token]);

    return (
        <div className='tourname'>
            {group ? (
                <h1 className="tourname__title">{group.party_name}</h1>
            ) : (
                <p>Loading...</p>
            )}

            <Link to='/grouproundstart'><button className='tourname__button'>Play Tour</button></Link>
        </div>
    );
};

export default LeaderBoardTourName;
