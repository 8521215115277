import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from '../src/pages/LoginPage/LoginPage'
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';
import LeaderBoardPage from './pages/LeaderBoardPage/LeaderBoardPage';
import DropDownHeader from './components/DropDownHeader/DropDownHeader';
import Tours from './pages/Tours/Tours';
import CourseSelect from './components/CourseSelect/CourseSelect';
import TrackRound from './pages/TrackRound/TrackRound';
import UserProfile from './pages/UserProfile/UserProfile';
import MakeTour from './pages/MakeTour/MakeTour';
import GroupCourseSelect from '../src/components/GroupCourseSelect/GroupCourseSelect';
import GroupScoreInput from './components/GroupScoreInput/GroupScoreInput';
import FindCourses from './components/FindCourses/FindCourses';
import EditProfile from './components/EditProfile/EditProfile';
import AboutPage from './pages/AboutPage/AboutPage';
import FriendsListPage from './pages/FriendsListPage/FriendsListPage';
import FriendsSearchPage from './pages/FriendsSearchPage/FriendsSearchPage';
import ActivityFeed from './pages/ActivityFeed/ActivityFeed';

function App() { 
  return (
    <>
      <BrowserRouter>
        <DropDownHeader />
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/register' element={<RegistrationPage />} />
          <Route path='/tours/:tourid' element={<LeaderBoardPage />} />
          <Route path='/tourslist' element={<Tours />} />
          <Route path='/createtour' element={<MakeTour />}/>
          <Route path='/start' element={<CourseSelect />}/>
          <Route path='/track/:id/:block' element={<TrackRound />}/> 
          <Route path='/' element={<UserProfile />}/>
          <Route path='/grouproundstart' element={<GroupCourseSelect />}/>
          <Route path="/grouptrack/:id/:block/:groupId" element={<GroupScoreInput />} />
          <Route path='/findcourses' element={<FindCourses />}/>
          <Route path='/editprofile' element={<EditProfile />}/>
          <Route path='/about' element={<AboutPage />}/>
          <Route path='/friendslist' element={<FriendsListPage />}/>
          <Route path='/friendsearch' element={<FriendsSearchPage />}/>
          <Route path='/activity' element={<ActivityFeed />}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
