import { useState } from 'react';
import './AboutPage.scss';
import FindCourseIcon from '../../assets/icons/find-courses.png';
import HoleDataIcon from '../../assets/icons/hole-data.png';
import ScoreCardIcon from '../../assets/icons/score-card.png';
import TournamentIcon from '../../assets/icons/tournaments.png';
import TrackScoreIcon from '../../assets/icons/track-score.png';

const coursesData = [
  { name: "Oakville Executive Angels View", city: "Oakville" },
  { name: "Oakville Executive Mystic Ridge", city: "Oakville" },
  { name: "Glenn Abbey Golf Club", city: "Oakville" },
  { name: "Pipers Heath Golf Club", city: "Milton" },
  { name: "Granite Ridge Golf Club Cobalt", city: "Milton" },
  { name: "Granite Ridge Golf Club Ruby", city: "Milton" },
  { name: "Rattle Snake Point Golf Club Copperhead", city: "Milton" },
  { name: "Rattle Snake Point Golf Club Sidewinder", city: "Milton" },
  { name: "Royal Ontario Golf Club", city: "Milton" },
  { name: "Lakview Golf Course", city: "Mississauga" },
  { name: "Braeben Golf Championship", city: "Mississauga" },
  { name: "Derry Dale Golf Club", city: "Mississauga" },
  { name: "Brampton Golf Club", city: "Brampton" },
  { name: "Streetsville Glen Golf Club", city: "Brampton" },
  { name: "Turnberry Golf Club", city: "Brampton" },
  { name: "Don Valley Golf Club", city: "North York" },
  { name: "Royal Woodbine Golf Club", city: "Etobicoke" },
  { name: "Humber Valley Golf Course", city: "Etobicoke" },
  { name: "Flemingdon Golf Club", city: "North York" },
  { name: "Scarlett Woods Golf Course", city: "York" },
  { name: "Markland Wood Golf Club", city: "Etobicoke" },
  { name: "Weston Golf and Country Club", city: "Etobicoke" },
  { name: "Toronto Golf Club Colt", city: "Mississauga" },
  { name: "Toronto Golf Club Watson", city: "Mississauga" },
  { name: "Centennial Park Golf Centre East", city: "Etobicoke" },
  { name: "Centennial Park Golf Centre West", city: "Etobicoke" },
  { name: "Centennial Park Golf Centre North", city: "Etobicoke" }
];

const featuresData = [
  { title: "Score Tracking", description: "Track your scores with ease and convenience, no fuss.", icon: TrackScoreIcon },
  { title: "Digital Scorecards", description: "Digital scorecards that are easy to fill and share.", icon: ScoreCardIcon },
  { title: "Hole-By-Hole Data", description: "Detailed data for each hole to help you strategize better.", icon: HoleDataIcon },
  { title: "Stroke Play Competitions", description: "Join competitions and enjoy the game with others.", icon: TournamentIcon },
  { title: "Find Courses", description: "Easily find and explore new golf courses around you.", icon: FindCourseIcon }
];

const AboutPage = () => {
  const [selectedCity, setSelectedCity] = useState(null);

  const toggleCity = (city) => {
    setSelectedCity(selectedCity === city ? null : city);
  };

  const groupedCourses = coursesData.reduce((acc, course) => {
    acc[course.city] = acc[course.city] || [];
    acc[course.city].push(course.name);
    return acc;
  }, {});

  return (
    <div className="about-page">
      <section className="header">
        <div className="header__nav">
          <h2 className="header__nav--title"><a href="/about" className="header__nav--link">The Tour</a></h2>
          <h6 className="header__nav--subtitle"><a href="/login" className="header__nav--link">Login</a></h6>
        </div>
        <div className="header__hero">
          <h1 className="header__hero--title">Welcome to The Tour</h1>
          <div className="header__hero--container">
            <h4 className="hero__container--section"><a href="#overview" className="hero__container--link">Overview</a></h4>
            <h4 className="hero__container--section"><a href="#features" className="hero__container--link">Features</a></h4>
            <h4 className="hero__container--section"><a href="#courses" className="hero__container--link">Courses</a></h4>
            <h4 className="hero__container--section"><a href="#about" className="hero__container--link">About</a></h4>
          </div>
        </div>
      </section>

       {/* Mission & Values Section */}
       <section className="mission" id="about">
        <div className="mission__divide">
          <h2 className="mission__divide--title">Our Mission</h2>
        </div>
        <p className="mission__paragraph">
          At The Tour, our mission is to demystify the game of golf, making it accessible and enjoyable for everyone. We aim to strip back the complexities of traditional golf apps, offering intuitive and user-friendly tools that enhance the golfing experience, connect the community, and promote the sport's true spirit: fun, relaxation, and camaraderie.
        </p>
      </section>

      {/* Overview Section */}
      <section className="overview" id="overview">
        <div className="overview__divide">
          <h2 className="overview__divide--title">Overview</h2>
        </div>
        <div className="overview__paragraph">
          <p className="overview__paragraph--text">Golf often starts as a deceptively simple sport, but as anyone who has played knows, it quickly reveals itself as much more. It’s not just a game; it’s an opportunity to connect with friends and family, to network, and even to travel. Yet, the golf apps and tools available today have turned this leisurely sport into something overwhelmingly complex, potentially alienating new and casual players.</p>
          <p className="overview__paragraph--text">That’s where The Tour comes in. Created by and for new golfers, our app is about stripping back the unnecessary complexities that have crept into the golf world. Our aim is to make golf more inclusive and straightforward, encouraging people to focus on what truly matters: enjoying the game.</p>
          <p className="overview__paragraph--text">With The Tour, you don’t need to worry about the exact yardage or the minutiae of game tracking—elements that can be intimidating for beginners. Instead, we provide the essentials: easy ways to find golf courses, track your scores simply, and engage in friendly competition. It’s golf, simplified, and brought back to its roots.</p>
          <p className="overview__paragraph--text">We believe that everyone should have the opportunity to enjoy golf. With The Tour, it’s not about perfecting every shot but about the joy of playing, the fresh air, and the company you keep. So, grab a club, bring a friend, and join us on The Tour—where golf is easy to love and even easier to play.</p>
        </div>
      </section>

      {/* Features Section */}
      <section className="features" id="features">
        <div className="features__divide">
          <h2 className="features__divide--title">Features</h2>
        </div>
        <div className="features__cards">
          {featuresData.map((feature, index) => (
            <div key={index} className="feature-card">
              <img src={feature.icon} alt={feature.title} className="feature-card__icon" />
              <h4 className="feature-card__title">{feature.title}</h4>
              <p className="feature-card__description">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Courses Section */}
      <section className="courses" id="courses">
        <div className="courses__divide">
          <h2 className="courses__divide--title">Our Courses</h2>
        </div>
        <div className="courses__container">
          <p>Explore courses from around the GTHA that you can play and compete on.</p>
          <div className="courses__list">
            {Object.keys(groupedCourses).map((city) => (
              <div key={city} className="courses__city" onClick={() => toggleCity(city)}>
                <h3 className="courses__city-name">{city} ({groupedCourses[city].length})</h3>
                {selectedCity === city && (
                  <ul className="courses__list--container">
                    {groupedCourses[city].map((course) => (
                      <li key={course} className="courses__list--name">{course}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer__links">
          <a href="#terms" className="footer__link--item">Terms of Service</a>
          <a href="#privacy" className="footer__link--item">Privacy Policy</a>
          <a href="#contact" className="footer__link--item">Contact</a>
        </div>
      </footer>
    </div>
  );
};

export default AboutPage;
